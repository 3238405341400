.App {
  text-align: center;
}

.App-header {
  background-color: #809bb3;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: #111111;
}

.App-sidebar {
}

.App-body {
  overflow-y: scroll;
  height: 90vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: #111111;
}

.App-inner {
  background-color: #555133;
  overflow: hidden;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: #111111;
}
